export default {
  name: {
    nb: 'Default',
  },
  slug: 'helseservicefag',
  namespace: '4ea9110d-1b68-497c-a4f1-6e10b5aeba72',
  assets: {
    logo: '/themes/default/images/logo.svg',
    favicon: '/themes/default/favicon.ico',
  },
  dbok: {
    nb: [{ link: 'https://reader.dbok.no/#/' }],
    nn: [{ link: 'https://reader.dbok.no/#/' }],
  },
  styles: [
    '/themes/default/css/default.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til Helseservicefag</h1><p>Vi arbeider med å videreutvikle nettressursen. I løpet av skoleåret 2021–22 kommer det flere yrkesoppgaver, fagbegrep, multimedia og et dokumentasjonsverktøy.</p>',
  },
  langs: [
    {
      code: 'nb',
      slug: 'helseservicefag',
      label: 'Bokmål',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Begreper',
        to: '/concepts/begreper',
      },
      {
        title: 'Aktiviteter',
        to: '/articles/aktiviteter',
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
      },
      {
        title: 'Hvordan har du det?',
        to: '/lookup/hvordan-har-du-det',
      },
      {
        title: 'Dokumentasjonsverktøyet',
        to: '/files/dokumentasjon',
      },
      {
        title: 'Lærerens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER',
      },
    ],
  },
}
